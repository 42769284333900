<template>
  <div class="done">
    <mt-header :title="$t('projectName')">
      <router-link to="/" slot="left">
        <mt-button icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="title">
      <!-- 审批中 -->
      <template v-if="item.contractStatus == 'APPROVE' || item.contractStatus == 'WAIT'">
        <img src="../../assets/wait.png" style="height: 100px;margin-top: 25px;">
        <div>{{$t('waitTips1')}}</div>
        <div class="refresh" @click="init">{{$t('waitTips2')}}</div>
      </template>
      <!-- 资料待修改 -->
      <template v-if="item.contractStatus=='SUPPLEMENTARY' || item.contractStatus=='SUPPLEMENT_IMAGE' || item.contractStatus=='SUPPLEMENT_BANK_CARD' || item.contractStatus=='SUPPLEMENT_IMAGE_CARD'">
        <img src="../../assets/applicationFailed.png" style="height: 100px;margin-top: 25px;">
        <div>{{$t('supplementaryTips1')}}</div>
        <div>{{$t('supplementaryTips2')}}</div>
        <div>{{$t('supplementaryTips3')}}</div>
      </template>
      <!-- 审批通过 -->
      <template v-if="item.contractStatus == 'LOAN'">
        <img src="../../assets/applicationPassed.png" style="height: 156px;margin-top: 10px;">
        <div>{{$t('passedTips1')}}</div>
      </template>
      <!-- 审批拒绝 -->
      <template v-if="item.contractStatus == 'REJECTED'">
        <img src="../../assets/applicationFailed.png" style="height: 100px;margin-top: 25px;">
        <div>{{$t('rejectedTips1')}}</div>
        <div>{{$t('rejectedTips2')}}</div>
      </template>
      <!-- 还款 -->
      <template v-if="item.contractStatus == 'NORMAL'">
        <img src="../../assets/toBeRepaid.png" style="height: 100px;margin-top: 25px;">
        <div>{{$t('toBeRepaid1')}}</div>
        <div>{{$t('toBeRepaid2')}}</div>
      </template>
      <!-- 已结清  -->
      <template v-if="item.contractStatus == 'SETTLE'">
        <img src="../../assets/settled.png" style="height: 100px;margin-top: 25px;">
        <div>{{$t('settled1')}}</div>
        <div>{{$t('settled2')}}</div>
      </template>
      <!-- 已逾期 -->
      <template v-if="item.contractStatus == 'OVERDUE'">
        <img src="../../assets/overdue.png" style="height: 100px;margin-top: 25px;">
        <div>{{$t('overdue1')}}</div>
        <div>{{$t('overdue2')}}</div>
      </template>
    </div>
    <div class="content" v-if="item.contractStatus != 'REJECTED'">
      <mu-list>
        <mu-list-item>
          <mu-list-item-title>{{ $t('loanDate') }}</mu-list-item-title>
          <mu-list-item-action>{{ item.applyDate }}</mu-list-item-action>
        </mu-list-item>
        <mu-divider></mu-divider>
        <mu-list-item>
          <mu-list-item-title>{{ $t('loanAmount') }}</mu-list-item-title>
          <mu-list-item-action>{{ item.applyAmount | formatMoney }}</mu-list-item-action>
        </mu-list-item>
        <mu-divider></mu-divider>
        <mu-list-item>
          <mu-list-item-title>{{ $t('repaymentPeriod') }}</mu-list-item-title>
          <mu-list-item-action>{{ item.loanTerms }} {{ $t('day') }}</mu-list-item-action>
        </mu-list-item>
        <mu-divider></mu-divider>
        <mu-list-item>
          <mu-list-item-title>{{ $t('amountDue') }}</mu-list-item-title>
          <mu-list-item-action>{{ item.totalRepayAmount | formatMoney }}</mu-list-item-action>
        </mu-list-item>
        <mu-divider></mu-divider>
        <mu-list-item>
          <mu-list-item-title>{{ $t('dueDate') }}</mu-list-item-title>
          <mu-list-item-action>{{ item.repayDate }}</mu-list-item-action>
        </mu-list-item>
        <mu-divider></mu-divider>
        <mu-list-item>
          <mu-list-item-title>{{ $t('contractStatus') }}</mu-list-item-title>
          <mu-list-item-action>{{ item.contractStatusName }}</mu-list-item-action>
        </mu-list-item>
      </mu-list>
    </div>
    <!--等待审批或审批通过-->
    <div
      class="msg"
      v-if="item.contractStatus == 'WAIT' || item.contractStatus == 'LOAN' || item.contractStatus =='SETTLE' || item.contractStatus =='OVERDUE'"
    >
      <div v-if="item.contractStatus=='WAIT'" >{{$t('waitMsg1')}}</div>
      <div v-if="item.contractStatus=='LOAN'" >{{$t('passedMsg1')}}</div>
      <div v-if="item.contractStatus=='LOAN'" >{{$t('passedMsg2')}}{{brand.serviceInfo.ccphone}}</div>
      <div v-if="item.contractStatus=='OVERDUE'">{{$t('overdueMsg1')}}</div>
      <div v-if="item.contractStatus=='SETTLE'">{{$t('settleMsg1')}}</div>
    </div>
    <div class="footer">
      <!-- 资料待修改 -->
      <template v-if="item.contractStatus=='SUPPLEMENTARY' || item.contractStatus=='SUPPLEMENT_IMAGE' || item.contractStatus=='SUPPLEMENT_BANK_CARD' || item.contractStatus=='SUPPLEMENT_IMAGE_CARD'">
        <mu-button full-width  color="#E7AD5C"  text-color="#18252A" @click="$router.push('/step/supplementary')">{{ $t("modifyImmediately") }}</mu-button>
      </template>
      <!-- 审批拒绝或撤销 -->
      <template v-else-if="item.contractStatus=='REJECTED'">
        <mu-button full-width  color="#E7AD5C"  text-color="#18252A" @click="$router.push('/')">{{ $t("reapply") }}</mu-button>
      </template>
      <!-- 查看还款通道 -->
      <template v-else-if="item.contractStatus == 'NORMAL' || item.contractStatus == 'OVERDUE'">
        <mu-button full-width  color="#E7AD5C"  text-color="#18252A" @click="$router.push(`/mine/repaymentInfo?applyId=${user.applyId}`)">{{ $t("repayment") }}</mu-button>
        <div class="tips">
          {{ $t('repaymentTips') }}
        </div>
      </template>
      <template v-else>
        <mu-button full-width color="#E7AD5C"  text-color="#18252A" @click="$router.push('/')">{{ $t("applyImmediately") }}</mu-button>
      </template>
    </div>
    <tab-bar />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { getUserInfo, contractDetail } from '../../utils/api'
import callPhone from '@/components/callPhone.vue'
import tabBar from '@/components/tabBar.vue'
import gtmPageView from '../../utils/gtm.js'
export default {
  name: "Done",
  components: {
    callPhone,
    tabBar,
  },
  data() {
    return {
      item: {},
    }
  },
  created() {
    gtmPageView(this)
    this.init()
  },
  computed: {
    ...mapState(["brand", "user"]),
    videoAuthFlag() {
      if (this.user.videoAuthFlag != 'Y'  && this.brand.videoAuthEnable == 'Y' && (this.user.applyStatus == 'APPROVE' || this.user.applyStatus == 'WAIT')) {
        return true
      }
      return false
    }
  },
  watch: {
    // 视频认证
    videoAuthFlag: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            this.$router.push('/apply/shootVideo')
          })
        }
      },
      immediate: true,
    }
  },
  methods: {
    ...mapMutations(["SET_USER", "SET_CHECK_CODE"]),
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      await Promise.all([
        this.getUser(),
        this.getContractDetail(),
      ])
      this.$indicator.close()
      this.$NProgress.done()
    },
    async getUser() {
      await this.$axios({
        method: "POST",
        url: getUserInfo,
      }).then((e) => {
        if (e.status.code == "000") {
          this.SET_USER(e.body);
        }
      }).catch(() => {})
    },
    async getContractDetail() {
      const formData = new FormData()
      formData.append('applyId', this.user.applyId)
      await this.$axios({
        method: 'post',
        url: contractDetail,
        data: formData
      })
        .then((e) => {
          if (e.status.code == '000') {
            this.item = e.body
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.done {
  .title {
    text-align: center;
    padding: 10px;
    color: #F15234;
    font-size: 12px;
    div {
      &:nth-last-child(2) {
        margin: 10px 0 5px 0;
        font-size: 14px;
        text-align: center;
        color: #1D1D1D;
        font-weight: 600;
      }
    }
    .refresh {
      color: red;
      font-size: 13px;
    }
    .icon {
      font-size: 60px;
    }
  }
  .content {
    margin: 10px;
    border-radius: 14px;
    border: .5px solid #E1E2E7;
    background: white;
    /deep/ .mint-cell-wrapper {
      padding: 0;
    }
    /deep/ .mint-cell-text {
      font-size: 18px;
      color: #9CA7AB;
    }
    .mint-cell {
      &:first-child {
        /deep/ .mint-cell-value {
          font-size: 13px;
          color: #9CA7AB;
        }
      }
    }
    /deep/ .mint-cell-value {
      font-size: 18px;
      color: #9CA7AB;
    }
  }
  .msg {
    margin: 20px 20px 0px 20px;
    position: relative;
    font-size: 12px;
    color: #F15234;
  }
  .footer {
    text-align: center;
    padding: 40px 10px 20px 10px;
    .mint-button {
      margin-bottom: 10px;
    }
  }
  .tips {
    margin: 10px;
    color: #FF4949;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
  }
  /deep/ .mu-raised-button {
    border-radius: 8px;
    height: 50px;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .mu-item-title {
    width: auto;
  }
}
</style>
